import React from "react";
import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Avatar,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { FaUserLock } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function CallToActionWithAnnotation() {
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      w={"full"}
      bg={"gray.800"}
    >
      <Container maxW={"3xl"}>
        <Stack
          as={Box}
          textAlign={"center"}
          align="center"
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Avatar
            src="./logo512.png"
            size={"2xl"}
            icon={<Icon as={FaUserLock} color={"gray.50"} />}
          />
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
            color={"red.900"}
          >
            AfricasKing <br />
            <Text
              as={"span"}
              color={"white"}
              fontSize={{ base: "1xl", sm: "2xl", md: "4xl" }}
            >
              Reseller Program
            </Text>
          </Heading>

          <Stack
            direction={"row"}
            spacing={3}
            align={"center"}
            alignSelf={"center"}
            position={"relative"}
          >
            <Button rounded={"full"} p={6} as={Link} to={"/register"}>
              Register
            </Button>
            <Button
              variant={"redBtn"}
              rounded={"full"}
              p={6}
              as={Link}
              to={"/login"}
            >
              Login
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
}
