import React, { useEffect, useState } from "react";

import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  useToast,
  Switch,
  Icon,
  Spinner,
  VStack,
  Box,
  Divider,
} from "@chakra-ui/react";
import { useAuth } from "../auth/useAuth";
import firebase from "../../utils/firebase";
import "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { FaExclamationTriangle } from "react-icons/fa";

export default function TelegramCard({ isOpen, onClose, msg }: any) {
  const toast = useToast();
  const { user } = useAuth();
  const [loadingState, setLoadingState] = useState(false);
  const [switches, setSwitches] = useState([]);

  const telegramSettingsRef = firebase
    .firestore()
    .collection("telegramBOT")
    .doc(user.uid);

  const telegramFunctionsRef = firebase
    .firestore()
    .collection("telegramBOT")
    .doc("AA_SETTINGS_WIZARD");

  const [value, loading, error] = useDocumentData(telegramSettingsRef);
  const [value2, loading2, error2] = useDocumentData(telegramFunctionsRef);

  useEffect(() => {
    if (value) setSwitches(value.settingsFunctions);
  }, [value]);

  const updateSetting = async (newValue: any) => {
    setLoadingState(true);
    setSwitches((prev) => {
      const index = prev.findIndex((item: any) => item.id === newValue.id);
      const newArray: any = [...prev];
      newArray[index].value = newValue.value;
      return newArray;
    });
    try {
      await fetch(process.env.REACT_APP_API_URL + "/telegramSettings", {
        method: "POST",
        body: JSON.stringify({
          uid: user.uid,
          id: newValue.id,
          value: newValue.value,
        }),
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((resp) => {
          setLoadingState(false);
          if (resp.status === 1) {
            toast({
              title: "Success",
              description: "Updated you settings successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Error",
              description:
                "An error occurred while updating your settings. Please try again later",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        });
    } catch (err) {
      setLoadingState(false);
      toast({
        title: "Error",
        description:
          "An error occurred while updating your settings. Please try again later",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Telegram Notifications</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error && (
            <Flex
              flexDir={"column"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Icon
                as={FaExclamationTriangle}
                fontSize={"8rem"}
                color={"red.500"}
              />
              <Text textAlign={"center"} fontSize={"xl"} mt={4}>
                An error occurred, please try again later.
                <br />
                If this issue persists please contact us
              </Text>
            </Flex>
          )}
          {loading && (
            <Flex
              flexDir={"column"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Spinner
                thickness="8px"
                speed="0.65s"
                emptyColor="gray.200"
                color="red.900"
                size="2xl"
              />
              <Text textAlign={"center"} fontSize={"xl"} mt={4}>
                Please Wait
              </Text>
            </Flex>
          )}
          {value && value2 && !loading && !error && (
            <VStack height="100%" width="100%" spacing={4}>
              <Text fontSize={"lg"}>
                Update your Telegram notification settings
              </Text>
              <Box
                py={5}
                px={5}
                textAlign={"center"}
                width={"100%"}
                borderWidth="1px"
                borderColor={"gray.600"}
                borderRadius="lg"
                overflow="hidden"
                fontSize={"lg"}
                whiteSpace={"pre-line"}
              >
                {switches.map((element: any) => {
                  const functionSettings = value2.options.find(
                    (x: any) => x.id === element.id
                  );
                  if (
                    !functionSettings ||
                    element.status !== "active" ||
                    functionSettings.status !== "active"
                  )
                    return <></>;
                  return (
                    <VStack spacing={4} w={"full"} h={"full"}>
                      <Flex
                        display="flex"
                        w={"full"}
                        h={"full"}
                        alignItems="center"
                        key={element.id}
                        mt={4}
                      >
                        <Text htmlFor={element.id} textAlign={"left"}>
                          {functionSettings.title}
                        </Text>
                        <Switch
                          al
                          isDisabled={loadingState}
                          ml={"auto"}
                          size={"lg"}
                          isChecked={element.value}
                          id={element.id}
                          onChange={(val) => {
                            updateSetting({
                              id: element.id,
                              value: val.target.checked,
                            });
                          }}
                        />
                      </Flex>
                      <Divider />
                    </VStack>
                  );
                })}
              </Box>
            </VStack>
          )}
          {!value && !loading && !error && (
            <Text fontSize={"lg"}>
              Message the admin to enable this feature
            </Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
