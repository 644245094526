import React from "react";

import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Icon,
  ModalFooter,
  Spinner,
  Box,
  VStack,
} from "@chakra-ui/react";
import firebase from "../../utils/firebase";
import "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { FaExclamationTriangle } from "react-icons/fa";
import { DateTime } from "luxon";

export default function SporstMessageCard({ isOpen, onClose }: any) {
  const sportsMessageRef = firebase
    .firestore()
    .collection("sports")
    .limit(1)
    .orderBy("added", "desc");
  const [values, loading, error] = useCollectionData(sportsMessageRef);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={"xl"}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sports</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error && (
            <Flex
              flexDir={"column"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Icon
                as={FaExclamationTriangle}
                fontSize={"8rem"}
                color={"red.500"}
              />
              <Text textAlign={"center"} fontSize={"xl"} mt={4}>
                An error occurred, please try again later.
                <br />
                If this issue persists please contact us
              </Text>
            </Flex>
          )}
          {loading && (
            <Flex
              flexDir={"column"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Spinner
                thickness="8px"
                speed="0.65s"
                emptyColor="gray.200"
                color="red.900"
                size="2xl"
              />
              <Text textAlign={"center"} fontSize={"xl"} mt={4}>
                Please Wait
              </Text>
            </Flex>
          )}
          {values && !loading && !error && (
            <VStack height="100%" width="100%" spacing={4}>
              <Flex flexDir={"column"} textAlign={"center"}>
                <Text fontSize={"lg"}>
                  We update the following message for special sporting events.
                </Text>
                <Text color={"red.900"} mt={0}>
                  UTC Timings
                </Text>
              </Flex>
              <Box
                py={5}
                textAlign={"center"}
                width={"100%"}
                borderWidth="1px"
                borderColor={"gray.600"}
                borderRadius="lg"
                overflow="hidden"
                fontSize={"lg"}
                whiteSpace={"pre-line"}
              >
                <Text>{values[0].message}</Text>
              </Box>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter w={"100%"}>
          {values && (
            <Text mr={"auto"}>
              Updated{" "}
              {DateTime.fromSeconds(values[0].added.seconds).toLocaleString({
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </Text>
          )}
          <Button variant={"redBtn"} onClick={onClose}>
            Return
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
