import React, { useState, useEffect } from "react";

import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  VStack,
  InputGroup,
  Input,
  InputLeftAddon,
  InputRightAddon,
  useClipboard,
  useToast,
  HStack,
  Flex,
  Icon,
  Stack,
} from "@chakra-ui/react";
import { useAuth } from "../auth/useAuth";
import { ErrorMessage, LoadingMessage } from "../card/Cards";
import { FaExclamationTriangle } from "react-icons/fa";
import Countdown from "react-countdown";

export default function TestAccountCard({ isOpen, onClose, msg }: any) {
  const [status, setStatus] = useState<any>(null);
  const [pendingTimer, setPendingTimer] = useState<any>(null);

  const [warning, setWarning] = useState<any>(null);
  const { user } = useAuth();
  const toast = useToast();

  useEffect(() => {
    if (msg.testLine && msg.testLine.last_testLine) {
      let lastLine = new Date(msg.testLine.last_testLine.seconds * 1000);

      lastLine = new Date(lastLine.getTime() + 12 * 60000 * 60);

      if (lastLine.getTime() > new Date().getTime()) {
        setStatus("exists");
      } else {
        setStatus("new");
      }
    } else if (msg.testLine && msg.testLine.status === "pending") {
      if (msg.testLine.last_pending) {
        let lastLine = new Date(msg.testLine.last_pending.seconds * 1000);

        lastLine = new Date(lastLine.getTime() + 180000);
        setPendingTimer(lastLine);

        if (lastLine.getTime() < new Date().getTime()) {
          setStatus("new");
        } else {
          setStatus("loading");
        }
      } else {
        setStatus("loading");
      }
    } else {
      setStatus("new");
    }
  }, [msg.testLine]);

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      setStatus("new");
      return <span></span>;
    } else {
      return <span></span>;
    }
  };

  async function newLine() {
    setStatus("loading");
    await fetch(process.env.REACT_APP_API_URL + "/testLines", {
      method: "POST",
      body: JSON.stringify({
        uid: user.uid,
      }),
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.status === 1) {
          toast({
            title: "Success",
            description:
              "Trial subscription is being created. Please allow up to 5 minutes",
            status: "success",
            duration: 8000,
            isClosable: true,
          });
        } else {
          if (!resp.data) setStatus("error");
          else if (resp.data === "loading") setStatus("loading");
          else {
            setStatus("warning");
            setWarning(resp.data);
          }
        }
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Trial Subscription</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {status && status === "loading" && (
            <Stack direction={"column"} spacing={3}>
              <LoadingMessage />
              <Countdown
                renderer={renderer}
                zeroPadTime={2}
                date={pendingTimer}
              />
            </Stack>
          )}
          {status && status === "error" && <ErrorMessage />}
          {status && status === "warning" && (
            <Flex
              flexDir={"column"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Icon
                as={FaExclamationTriangle}
                fontSize={"8rem"}
                color={"orange.500"}
              />
              <Text textAlign={"center"} fontSize={"xl"} mt={4}>
                {warning}
              </Text>
            </Flex>
          )}

          {status && status === "exists" && msg.testLine && (
            <VStack spacing={6} justify={"center"} align="center">
              <Text textAlign={"center"} fontSize={"md"}>
                The following are the credentials to be used within
                <Text color={"red.900"}>
                  THE AFRICA KING'S APPLICATIONS ONLY
                </Text>
              </Text>
              <InputsSubscription
                name={"Username"}
                value={msg.testLine?.username}
              />
              <InputsSubscription
                name={"Password"}
                value={msg.testLine?.password}
              />
              <InputsSubscription
                name={"URL"}
                value={"apple.africasking.com"}
              />
              <CopyAllINPUT
                value={`Username: ${msg.testLine?.username}\nPassword: ${msg.testLine?.password}\nURL: apple.africasking.com`}
              />
              <Text mb={0} textAlign={"center"}>
                Active for 24 hours
                <br />
                This account could be turned off during a major sporting event
              </Text>
              <Button
                isFullWidth
                variant={"redBtn"}
                onClick={async () => {
                  await newLine();
                }}
              >
                Request New Line
              </Button>
            </VStack>
          )}
          {status && status === "new" && (
            <Flex justify={"center"} align="center" flexDir={"column"}>
              <Text textAlign={"center"}>
                Trial subscriptions are limited and are restricted to 5 account
                per 24 hours. This feature could be disabled without any notice.
                <br />
                <br />
                Note: This feature is usually disable during major sporting
                events and previous subscriptions will be disabled
              </Text>

              <Stack spacing={5} w={"full"} mt={8} mb={4}>
                <HStack w={"full"}>
                  <Button onClick={onClose} isFullWidth colorScheme={"gray"}>
                    Cancel
                  </Button>
                  <Button
                    isFullWidth
                    variant={"redBtn"}
                    onClick={async () => {
                      await newLine();
                    }}
                  >
                    Request
                  </Button>
                </HStack>
              </Stack>
            </Flex>
          )}
          {status && status === "error" && (
            <VStack spacing={6} justify={"center"} align="center">
              <Text>An error occurred please try again later</Text>
            </VStack>
          )}
        </ModalBody>
        {status && status !== "new" && status !== "loading" && (
          <ModalFooter>
            <Button variant={"redBtn"} onClick={onClose}>
              Return
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}

function InputsSubscription({ name, value }: any) {
  const { hasCopied, onCopy } = useClipboard(value);

  return (
    <InputGroup>
      <InputLeftAddon children={name} p={2} />
      <Input value={value} isReadOnly placeholder={name} variant={"redInput"} />
      <InputRightAddon p={0}>
        <Button
          onClick={onCopy}
          colorScheme={"gray"}
          _focus={{ boxShadow: "unset" }}
        >
          {hasCopied ? "Copied" : "Copy"}
        </Button>
      </InputRightAddon>
    </InputGroup>
  );
}

function CopyAllINPUT({ value }: any) {
  const { hasCopied, onCopy } = useClipboard(value);

  return (
    <Button variant={"redBtn"} onClick={onCopy}>
      {hasCopied ? "Copied All" : "Copy All"}
    </Button>
  );
}
