import React from "react";

import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  chakra,
  HStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  Stack,
  useToast,
  FormHelperText,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { useAuth } from "../auth/useAuth";

export default function BalanceCard({ isOpen, onClose, msg }: any) {
  const { user } = useAuth();

  const toast = useToast();
  const parse = (val: any) => val.replace(/^\$/, "");

  function validateAmount(value: any) {
    let error;

    if (!parse(value)) {
      error = "Amount is required";
    }
    return error;
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Balance</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text textAlign={"center"} fontSize={"lg"}>
            Your Current Balance:
            <chakra.b display={"block"} color={"red.900"} fontSize={"3xl"}>
              $ {msg} USD
            </chakra.b>
          </Text>
          <Formik
            initialValues={{
              amount: "50.00",
              auth: "",
            }}
            onSubmit={async (values, actions) => {
              if (parse(values.amount) < 50) {
                actions.setErrors({ auth: "Minimum of $50" });
              } else if (parse(values.amount) > msg) {
                actions.setErrors({
                  auth: "The account does not have enough balance",
                });
              } else {
                try {
                  fetch(process.env.REACT_APP_API_URL + "/withdraw", {
                    method: "POST",
                    mode: "cors",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      amount: parse(values.amount),
                      uid: user.uid,
                    }),
                  })
                    .then((res) => res.json())
                    .then(
                      (result) => {
                        if (result.status === 1) {
                          toast({
                            title: "Payout Requested",
                            description:
                              "You will receive an email once the payment has been sent",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                          });
                          actions.setSubmitting(false);
                          onClose();
                        } else {
                          toast({
                            title: result.data,
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                          actions.setSubmitting(false);
                        }
                      },
                      (error) => {
                        toast({
                          title: "Error Occurred",
                          status: "error",
                          duration: 9000,
                          isClosable: true,
                        });
                        actions.setSubmitting(false);
                      }
                    );
                } catch (error) {
                  actions.setErrors({ auth: error });
                  actions.setSubmitting(false);
                }
              }
            }}
          >
            {({ errors, isSubmitting, values, setFieldValue }) => (
              <Form style={{ width: "100%" }}>
                <Flex align="center" w={"full"} flexDir={"column"}>
                  <Field name="amount" validate={validateAmount}>
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.amount && form.touched.amount}
                        my={6}
                      >
                        <FormLabel htmlFor="amount">
                          Amount
                          <Text display={"inline"} fontSize={"xs"} ml={1}>
                            ($50 Minimum)
                          </Text>
                        </FormLabel>

                        <NumberInput
                          variant={"redNumberInput"}
                          onChange={(valueString) => {
                            setFieldValue("amount", parse(valueString));
                          }}
                          value={values.amount}
                          precision={2}
                          min={50}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>

                        <FormHelperText>
                          Input the amount that you would like to withdraw
                        </FormHelperText>
                        <FormErrorMessage>
                          {form.errors.amount}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {errors.auth && (
                    <Text align="center" fontSize="sm" mt="2" color={"red.300"}>
                      {errors.auth}
                    </Text>
                  )}
                  <Stack spacing={5} w={"full"} mt={8} mb={4}>
                    <HStack w={"full"}>
                      <Button
                        onClick={onClose}
                        isFullWidth
                        colorScheme={"gray"}
                      >
                        Cancel
                      </Button>
                      <Button
                        isFullWidth
                        variant={"redBtn"}
                        isLoading={isSubmitting}
                        type="submit"
                      >
                        Request
                      </Button>
                    </HStack>
                  </Stack>
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
