import React from "react";

import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  chakra,
} from "@chakra-ui/react";

export default function ReferralCodeCard({ isOpen, onClose, msg }: any) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Referral Code</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text textAlign={"center"} fontSize={"lg"}>
            Your Code:
            <chakra.b display={"block"} color={"red.900"} fontSize={"3xl"}>
              {msg}
            </chakra.b>
          </Text>
          <Text textAlign={"center"} mt={5} fontSize={"md"}>
            Make sure to mention your referral code to your users when they sign
            up. It is the only way we can identify that the user was referred by
            you.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant={"redBtn"} onClick={onClose}>
            Return
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
