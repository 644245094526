import React, { useState, useEffect, useContext, createContext } from "react";
import { useHistory } from "react-router-dom";

import firebase from "../../utils/firebase";
import "firebase/auth";
import "firebase/firestore";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export function ProvideAuth({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const LoginStatus = async () => {
    return firebase
      .firestore()
      .doc("settings/reseller")
      .get()
      .then((doc) => {
        if (!doc.data().login)
          throw Object.assign(new Error("unavailable_login"), {
            code: "unavailable_login",
          });
      });
  };

  const LoginReseller = async (uid) => {
    return firebase
      .firestore()
      .collection("resellers")
      .doc(uid)
      .get()
      .then((doc) => {
        if (!doc.data()) {
          throw Error();
        }
      })
      .catch((err) => {
        signOut();
        throw Object.assign(new Error("unauthorized_login"), {
          code: "unauthorized_login",
        });
      });
  };

  const sendEmailVerification = async (currentUser) => {
    return await firebase
      .auth()
      .currentUser.sendEmailVerification({
        url: "https://reseller.africasking.com/login",
      })
      .then(function () {
        return true;
      })
      .catch(function (error) {
        throw Object.assign(new Error("error"), {
          code: "error",
        });
      });
  };

  const EmailAuth = async (email, password) => {
    await LoginStatus();
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (response) => {
        if (!response.user.emailVerified) {
          await sendEmailVerification(response.user);
          signOut();
          throw Object.assign(new Error("unverified_email"), {
            code: "unverified_email",
          });
        } else {
          await LoginReseller(response.user.uid);
          setUser(response.user);
          history.push("/dashboard");
        }
      });
  };

  const SignUp = async (postData, callback) => {
    await fetch(process.env.REACT_APP_API_URL + "/register", {
      method: "POST",
      body: JSON.stringify(postData),
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        async (res) => {
          callback(res);
        },
        (error) => {
          callback({ status: 0, data: error.message });
        }
      );
  };

  const signOut = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };

  const sendPasswordResetEmail = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };
  const ErrorHandlingMsg = (code) => {
    if (code === "auth/invalid-email") return "This email address is invalid";
    else if (code === "auth/email-already-exists")
      return "The email address provided already exists";
    else if (code === "auth/user-disabled")
      return "This account has been disabled";
    else if (code === "auth/user-not-found")
      return "Invalid email or password, please try again";
    else if (code === "auth/wrong-password")
      return "Invalid email or password, please try again";
    else if (code === "auth/email-already-in-use")
      return "This email address is already in use";
    else if (code === "auth/invalid-email")
      return "This email address is incorrect";
    else if (code === "auth/weak-password" || code === "auth/invalid-password")
      return "Please input a stronger password";
    else if (code === "auth/account-exists-with-different-credential")
      return "This account is associated with a different login provider";
    else if (code === "auth/popup-blocked")
      return "Please allow browser popups";
    else if (code === "unavailable_login")
      return "The website is currently under maintenance. Please try again later";
    else if (code === "unauthorized_login")
      return "The account is still pending approval or is unauthorized to login on this website";
    else if (code === "unavailable_register")
      return "Registration is currently unavailable. Please try again later";
    else if (code === "auth/popup-closed-by-user")
      return "The popup has been closed, please try again";
    else if (code === "unverified_email")
      return "Please verify your email and make sure to check your junk mail";
    else return "An error occurred, please try again later";
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const value = {
    user,
    EmailAuth,
    SignUp,
    signOut,
    sendPasswordResetEmail,
    ErrorHandlingMsg,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
