import React from "react";

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  Avatar,
  FormErrorMessage,
  Link as LinkUI,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { useAuth } from "../components/auth/useAuth";
import { Formik, Form, Field } from "formik";
import { FaUserLock } from "react-icons/fa";
export default function SimpleCard(props: any) {
  const toast = useToast();

  function validateEmail(value: any) {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }
  function validatePassword(value: any) {
    let error;
    if (!value) {
      error = "Password is required";
    }
    return error;
  }
  const { EmailAuth, ErrorHandlingMsg, sendPasswordResetEmail } = useAuth();
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      w={"full"}
      bg={"gray.800"}
    >
      <Stack
        spacing={8}
        maxW={{ base: "full", sm: "lg" }}
        alignItems={"center"}
        py={12}
      >
        <Stack align={"center"} textAlign={"center"}>
          <Heading fontSize={{ base: "3xl", sm: "4xl" }}>
            Sign In - Reseller
          </Heading>
          <Text fontSize={"lg"} color={"gray.600"} textAlign={"center"}>
            Don't have an account?
            <LinkUI as={Link} to={"/register"} display={"block"}>
              Register
            </LinkUI>
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={"gray.700"}
          boxShadow={"lg"}
          p={8}
          w={{ base: "95vw", sm: "sm" }}
        >
          <Formik
            initialValues={{ email: "", password: "", auth: "" }}
            onSubmit={async (values, actions) => {
              try {
                await EmailAuth(values.email, values.password);
              } catch (error) {
                actions.setErrors({ auth: ErrorHandlingMsg(error.code) });
              }
              actions.setSubmitting(false);
            }}
          >
            {({ errors, isSubmitting, values, setErrors }) => (
              <Stack spacing={4} align="center" w={"full"}>
                <Avatar
                  src="./logo512.png"
                  size={"xl"}
                  icon={<Icon as={FaUserLock} color={"gray.50"} />}
                  as={Link}
                  to={"/"}
                />
                <Form style={{ width: "100%" }}>
                  <Stack spacing={4} align="center" w={"full"}>
                    <Field name="email" validate={validateEmail}>
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <FormLabel htmlFor="email">Email Address</FormLabel>
                          <Input
                            variant={"redInput"}
                            {...field}
                            id="email"
                            autoComplete="username"
                            placeholder="Email address"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="password" validate={validatePassword}>
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.password && form.touched.password
                          }
                        >
                          <FormLabel htmlFor="password">Password</FormLabel>
                          <Input
                            variant={"redInput"}
                            {...field}
                            id="password"
                            placeholder="Password"
                            type="password"
                            autoComplete="current-password"
                          />
                          <FormErrorMessage>
                            {form.errors.password}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    {errors.auth && (
                      <Text
                        align="center"
                        fontSize="sm"
                        mt="2"
                        color={"red.300"}
                      >
                        {errors.auth}
                      </Text>
                    )}
                    <Stack spacing={5} w={"full"}>
                      <LinkUI
                        onClick={() => {
                          sendPasswordResetEmail(values.email)
                            .then(() => {
                              toast({
                                title:
                                  "Reset password has been successfully sent if the email address exists in our records 👍",
                                status: "success",
                                duration: 9000,
                                isClosable: true,
                              });
                            })
                            .catch((e: any) => {
                              if (e.code === "auth/user-not-found") {
                                toast({
                                  title:
                                    "Reset password has been successfully sent if the email address exists in our records 👍",
                                  status: "success",
                                  duration: 9000,
                                  isClosable: true,
                                });
                              } else {
                                setErrors({
                                  auth: ErrorHandlingMsg(e.code, "reset"),
                                });
                              }
                            });
                        }}
                      >
                        Forgot password?
                      </LinkUI>
                      <Button
                        variant={"redBtn"}
                        isLoading={isSubmitting}
                        type="submit"
                        isFullWidth
                      >
                        Sign in
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              </Stack>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  );
}
