import React from "react";

import {
  Stack,
  Flex,
  Text,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  HStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  Select,
} from "@chakra-ui/react";

import { useAuth } from "../../components/auth/useAuth";
import { Formik, Form, Field } from "formik";

export default function ReportIssueCard({ isOpen, onClose }: any) {
  const { user } = useAuth();
  const toast = useToast();

  function validateMessage(value: any) {
    let error;
    if (!value) {
      error = "Message is required";
    } else if (value.length <= 20) {
      error = "Message needs to be at least 20 characters long";
    }
    return error;
  }
  function validateReason(value: any) {
    let error;
    if (!value || value === "0") {
      error = "Reason is required";
    }
    return error;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Report Issue</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{ message: "", reason: "0", auth: "" }}
            onSubmit={async (values, actions) => {
              try {
                await fetch(process.env.REACT_APP_API_URL + "/report", {
                  method: "POST",
                  body: JSON.stringify({
                    uid: user.uid,
                    message: values.message,
                    reason: values.reason,
                  }),
                  mode: "cors",
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                  .then((res) => res.json())
                  .then((resp) => {
                    if (resp.status === 1) {
                      toast({
                        title: "Report Sent",
                        description:
                          "We have received your report and will get back to your shortly",
                        status: "success",
                        duration: 6000,
                        isClosable: true,
                      });
                      actions.setSubmitting(false);
                      onClose();
                    } else {
                      actions.setErrors({
                        auth: "Error occurred please try again later",
                      });
                      actions.setSubmitting(false);
                    }
                  });
              } catch (error) {
                actions.setErrors({
                  auth: "Error occurred please try again later",
                });
                actions.setSubmitting(false);
              }
            }}
          >
            {({ errors, isSubmitting }) => (
              <Form style={{ width: "100%" }}>
                <Flex align="center" w={"full"} flexDir={"column"}>
                  <Text textAlign={"center"} mb={6}>
                    If you have any questions or issue please send us a request
                    and we will get back to you shorty.
                  </Text>
                  <Field name="reason" validate={validateReason}>
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.reason && form.touched.reason}
                        mb={6}
                      >
                        <FormLabel htmlFor="reason">Reason</FormLabel>
                        <Select {...field} id="reason" variant={"redSelect"}>
                          <option value="0" disabled>
                            Select Reason
                          </option>
                          <option value="media">Media Issue</option>
                          <option value="connection">Connection Issue</option>
                          <option value="payment">Payment Issue</option>
                          <option value="payment">Subscription Issue</option>
                          <option value="other">Other</option>
                        </Select>
                        <FormErrorMessage>
                          {form.errors.reason}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="message" validate={validateMessage}>
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.message && form.touched.message}
                      >
                        <FormLabel htmlFor="message">Message</FormLabel>
                        <Textarea
                          variant={"redTextarea"}
                          {...field}
                          id="message"
                          placeholder="Please input a descriptive description of the issue you are facing"
                        />
                        <FormErrorMessage>
                          {form.errors.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {errors.auth && (
                    <Text align="center" fontSize="sm" mt="2" color={"red.300"}>
                      {errors.auth}
                    </Text>
                  )}
                  <Stack spacing={5} w={"full"} mt={8} mb={4}>
                    <HStack w={"full"}>
                      <Button
                        onClick={onClose}
                        isFullWidth
                        colorScheme={"gray"}
                      >
                        Cancel
                      </Button>
                      <Button
                        isFullWidth
                        variant={"redBtn"}
                        isLoading={isSubmitting}
                        type="submit"
                      >
                        Send
                      </Button>
                    </HStack>
                  </Stack>
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
