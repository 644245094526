import React from "react";
import { ChakraProvider, Flex } from "@chakra-ui/react";
import PrivateRoute from "./components/auth/privateRoute";
import { Switch, Route, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";

import { ProvideAuth } from "./components/auth/useAuth";
import theme from "./utils/theme";

export const App = () => {
  const location = useLocation();

  return (
    <ChakraProvider theme={theme}>
      <ProvideAuth>
        <Flex minH={"100vh"} minW={"100vw"} maxW={"100vw"}>
          <Switch location={location} key={location.key}>
            <Route exact path="/" component={Home} />

            <Route path="/login" component={Login} />
            <Route path="/register" component={Registration} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
          </Switch>
        </Flex>
      </ProvideAuth>
    </ChakraProvider>
  );
};
