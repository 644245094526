import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  styles: {
    global: {
      "html, body": {
        color: "white",
      },
    },
  },
  colors: {
    red: {
      900: "#f51315",
    },
    blue: {
      900: "#0D0C1D",
    },
  },
  components: {
    Switch: {
      baseStyle: {
        track: {
          bg: "gray.500",
          _checked: {
            bg: "red.900",
          },
          _focus: {
            boxShadow: "0 0 0 1px #f51315",
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        closeButton: {
          _focus: {
            boxShadow: "0 0 0 1px #f51315",
          },
        },
      },
    },
    Button: {
      variants: {
        redBtn: {
          bg: "red.900",
          _hover: {
            bg: "red.500",
          },
          _focus: {
            boxShadow: "0 0 0 1px #f51315",
          },
        },
      },
    },
    Link: {
      baseStyle: {
        color: "red.900",
        _focus: {
          boxShadow: "none",
        },
      },
    },
    NumberInput: {
      variants: {
        redNumberInput: {
          field: {
            bg: "inherit",
            borderRadius: "md",
            border: "1px solid",
            borderColor: "gray.600",
            background: "inherit",
            _focus: {
              boxShadow: "0 0 0 1px #f51315",
              borderColor: "red.900",
            },
          },
        },
      },
    },
    Input: {
      variants: {
        redInput: {
          field: {
            bg: "inherit",
            borderRadius: "md",
            border: "1px solid",
            borderColor: "gray.600",
            background: "inherit",
            _focus: {
              boxShadow: "0 0 0 1px #f51315",
              borderColor: "red.900",
            },
          },
        },
      },
    },
    Textarea: {
      variants: {
        redTextarea: {
          bg: "inherit",
          borderRadius: "md",
          border: "1px solid",
          borderColor: "inherit",
          background: "inherit",
          _focus: {
            boxShadow: "0 0 0 1px #f51315",
            borderColor: "red.900",
          },
        },
      },
    },
    Select: {
      variants: {
        redSelect: {
          field: {
            bg: "inherit",
            borderRadius: "md",
            border: "1px solid",
            borderColor: "inherit",
            background: "inherit",
            _focus: {
              boxShadow: "0 0 0 1px #f51315",
              borderColor: "red.900",
            },
            _hover: {
              cursor: "pointer",
            },
          },
        },
      },
    },
    Spinner: {
      sizes: {
        "2xl": {
          w: "8rem",
          h: "8rem",
        },
      },
    },
  },
});
