import React, { useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  Avatar,
  FormErrorMessage,
  Link as LinkUI,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormHelperText,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { useAuth } from "../components/auth/useAuth";
import { Formik, Form, Field } from "formik";
import { FaCheckCircle, FaExclamationCircle, FaUserLock } from "react-icons/fa";

export default function Registration() {
  function validateEmail(value: any) {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }
  function validateFName(value: any) {
    let error;

    if (!value) {
      error = "First name is required";
    } else if (/[^a-zA-Z]/.test(value)) {
      error = "First name invalid";
    }
    return error;
  }

  function validateLName(value: any) {
    let error;

    if (!value) {
      error = "Last name is required";
    } else if (/[^a-zA-Z]/.test(value)) {
      error = "Last name invalid";
    }
    return error;
  }
  function validatePassword(value: any) {
    let error;
    if (!value) {
      error = "Password is required";
    }
    return error;
  }
  function validatePasswordConfirmation(value: any) {
    let error;
    if (!value) {
      error = "Password Confirmation is required";
    }
    return error;
  }
  function validateReferralCode(value: any) {
    let error;
    var letters = /^[0-9A-Z]+$/;

    if ((value?.length < 5 && value?.length >= 0) || value?.length > 5) {
      error = "Referral code is invalid";
    } else if (!value.match(letters)) {
      error = "Referral code is invalid";
    }
    return error;
  }
  const { SignUp, ErrorHandlingMsg } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modal, setModal] = useState("");
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      w={"full"}
      bg={"gray.800"}
    >
      <Stack
        spacing={8}
        maxW={{ base: "full", sm: "lg" }}
        alignItems={"center"}
        py={12}
      >
        <Stack align={"center"}>
          <Heading fontSize={{ base: "3xl", sm: "4xl" }}>
            Sign Up - Reseller
          </Heading>
          <Text fontSize={"lg"} color={"gray.600"} textAlign={"center"}>
            Already have an account?
            <LinkUI as={Link} to={"/login"} display={"block"}>
              Login
            </LinkUI>
          </Text>
        </Stack>
        <Flex align={"center"} justify={"center"}>
          <Box
            rounded={"lg"}
            bg={"gray.700"}
            boxShadow={"lg"}
            p={8}
            minW={{ base: "95vw", sm: "sm" }}
            w={{ base: "95vw", sm: "sm" }}
          >
            <Formik
              initialValues={{
                email: "",
                password: "",
                auth: "",
                passwordConfirm: "",
                referralCode: "",
                fName: "",
                lName: "",
              }}
              onSubmit={async (values, actions) => {
                if (values.password !== values.passwordConfirm) {
                  actions.setErrors({ auth: "Passwords do not match" });
                } else {
                  try {
                    await SignUp(
                      {
                        email: values.email,
                        password: values.password,
                        code: values.referralCode,
                        full_name: values.fName + " " + values.lName,
                      },
                      (res: any) => {
                        if (res.status !== 1) {
                          actions.setErrors({
                            auth: res.data,
                          });
                        } else {
                          actions.resetForm({});
                          setModal("success");
                          onOpen();
                        }
                      }
                    );
                  } catch (error) {
                    actions.setErrors({ auth: ErrorHandlingMsg(error.code) });
                    setModal("failed");
                    onOpen();
                  }
                }
                actions.setSubmitting(false);
              }}
            >
              {({ errors, isSubmitting }) => (
                <Stack spacing={4} align="center" w={"full"}>
                  <Avatar
                    src="./logo512.png"
                    size={"xl"}
                    icon={<Icon as={FaUserLock} color={"gray.50"} />}
                    as={Link}
                    to={"/"}
                  />
                  <Form style={{ width: "100%" }}>
                    <Stack spacing={4} align="center" w={"full"}>
                      <Field name="fName" validate={validateFName}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.fName && form.touched.fName}
                          >
                            <FormLabel htmlFor="fName">First Name</FormLabel>
                            <Input
                              variant={"redInput"}
                              {...field}
                              id="fName"
                              placeholder="First Name"
                            />
                            <FormErrorMessage>
                              {form.errors.fName}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="lName" validate={validateLName}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.lName && form.touched.lName}
                          >
                            <FormLabel htmlFor="lName">Last Name</FormLabel>
                            <Input
                              variant={"redInput"}
                              {...field}
                              id="lName"
                              placeholder="Last Name"
                            />
                            <FormErrorMessage>
                              {form.errors.lName}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="email" validate={validateEmail}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.email && form.touched.email}
                          >
                            <FormLabel htmlFor="email">Email Address</FormLabel>
                            <Input
                              variant={"redInput"}
                              {...field}
                              id="email"
                              autoComplete="username"
                              placeholder="Email address"
                            />
                            <FormErrorMessage>
                              {form.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="password" validate={validatePassword}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.password && form.touched.password
                            }
                          >
                            <FormLabel htmlFor="password">Password</FormLabel>
                            <Input
                              variant={"redInput"}
                              {...field}
                              id="password"
                              placeholder="Password"
                              type="password"
                              autoComplete="current-password"
                            />
                            <FormErrorMessage>
                              {form.errors.password}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="passwordConfirm"
                        validate={validatePasswordConfirmation}
                      >
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.passwordConfirm &&
                              form.touched.passwordConfirm
                            }
                          >
                            <FormLabel htmlFor="passwordConfirm">
                              Confirm Password
                            </FormLabel>
                            <Input
                              variant={"redInput"}
                              {...field}
                              id="passwordConfirm"
                              placeholder="Confirm Password"
                              type="password"
                              autoComplete="current-password"
                            />
                            <FormErrorMessage>
                              {form.errors.passwordConfirm}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="referralCode"
                        validate={validateReferralCode}
                      >
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.referralCode &&
                              form.touched.referralCode
                            }
                          >
                            <FormLabel htmlFor="referralCode">
                              Referral Code Wanted
                            </FormLabel>
                            <Input
                              variant={"redInput"}
                              {...field}
                              id="referralCode"
                              placeholder="Referral Code"
                              type="text"
                              onInput={(e: any) =>
                                (e.target.value = (
                                  "" + e.target.value
                                ).toUpperCase())
                              }
                            />
                            <FormHelperText>
                              5 characters required
                            </FormHelperText>
                            <FormErrorMessage>
                              {form.errors.referralCode}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      {errors.auth && (
                        <Text
                          align="center"
                          fontSize="sm"
                          mt="2"
                          color={"red.300"}
                        >
                          {errors.auth}
                        </Text>
                      )}
                      <Stack w={"full"}>
                        <Button
                          mt={5}
                          variant={"redBtn"}
                          isLoading={isSubmitting}
                          type="submit"
                          isFullWidth
                        >
                          Register
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                </Stack>
              )}
            </Formik>
          </Box>
        </Flex>
      </Stack>
      {modal === "failed" && <ModalFailed isOpen={isOpen} onClose={onClose} />}
      {modal === "success" && (
        <ModalSuccess isOpen={isOpen} onClose={onClose} />
      )}
    </Flex>
  );
}

const ModalSuccess = ({ isOpen, onClose }: any) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered size={"md"}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Registration Pending</ModalHeader>
      <ModalCloseButton />
      <ModalBody textAlign={"center"}>
        <Icon as={FaCheckCircle} color={"green.500"} fontSize={"8rem"} />
        <Text mt={6}>
          Thank you for signing up. We received your request and will get back
          to your request shorty. Please keep an eye on your email
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button variant={"redBtn"} as={Link} to={"/"}>
          Return
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

const ModalFailed = ({ isOpen, onClose }: any) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered size={"md"}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Registration Failed</ModalHeader>
      <ModalCloseButton />
      <ModalBody textAlign={"center"}>
        <Icon as={FaExclamationCircle} color={"red.900"} fontSize={"8rem"} />
        <Text mt={6}>
          An error occurred while trying to register your account. Please try
          again later. If the issue persists please contact us
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button variant={"redBtn"} onClick={onClose}>
          Return
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
