import firebase from "firebase/app";

// Add your Firebase credentials
firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appID: process.env.REACT_APP_FIREBASE_APP_ID,
});

export default firebase;
