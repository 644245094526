import React, { useEffect, useState } from "react";

import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  chakra,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  useClipboard,
  Stack,
  FormControl,
  Input,
  useToast,
} from "@chakra-ui/react";
import {
  FaCheckCircle,
  FaClipboard,
  FaEraser,
  FaSearch,
  FaTv,
} from "react-icons/fa";
import { Formik, Form, Field } from "formik";

import firebase from "../../utils/firebase";
import "firebase/firestore";
import { useAuth } from "../auth/useAuth";

export default function SubscriptionCard({ isOpen, onClose, msg }: any) {
  const [viewLine, setViewLine] = useState<any>(null);
  const [isClicked, setIsClicked] = useState<any>(null);
  const { user } = useAuth();
  const [search, setSearch] = useState<any>("");
  const [subscriptions, setSubscriptions] = useState<any>([]);
  const toast = useToast();

  const getLine = async (email: any) => {
    setIsClicked(true);
    return firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .where("reseller", "==", user.uid)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size === 0) {
          toast({
            title: "User doesn't exist",
            description: "Contact the admin for more information.",
            status: "error",
            duration: 6000,
            isClosable: true,
          });
          setIsClicked(false);
        } else {
          querySnapshot.forEach(async (doc1) => {
            return await firebase
              .firestore()
              .collection("accounts")
              .where("user", "==", doc1.id)
              .limit(1)
              .get()
              .then((querySnapshot) => {
                if (querySnapshot.size === 0) {
                  toast({
                    title: "Subscription doesn't exist",
                    description: "Contact the admin for more information.",
                    status: "error",
                    duration: 6000,
                    isClosable: true,
                  });
                  setIsClicked(false);
                } else {
                  querySnapshot.forEach((doc) => {
                    setViewLine({
                      username: doc.data().username,
                      password: doc.data().password,
                    });
                    setIsClicked(false);
                  });
                }
              });
          });
        }
      })
      .catch((er) => console.log(er));
  };

  const handleClose = () => {
    setViewLine(null);
  };

  useEffect(() => {
    if (msg.accounts) {
      if (search !== "") {
        setSubscriptions(
          msg.accounts.filter((subs: any) => subs.includes(search))
        );
      } else {
        setSubscriptions([...msg.accounts].reverse());
      }
    }
  }, [search]);

  useEffect(() => {
    if (msg.accounts) setSubscriptions([...msg.accounts].reverse());
  }, [msg]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={"3xl"}
        scrollBehavior={"inside"}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Subscriptions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack direction={"column"} spacing={4}>
              {msg && (
                <Text textAlign={"center"} fontSize={"lg"}>
                  You currently have
                  <chakra.b color={"red.900"}>
                    {" "}
                    {msg.subscriptions} ACTIVE{" "}
                  </chakra.b>
                  subscriptions.
                </Text>
              )}
              {msg &&
                msg.accounts &&
                msg.accounts.length !== msg.subscriptions && (
                  <Text
                    textAlign={"center"}
                    fontSize={"lg"}
                    color={"orange.400"}
                  >
                    Warning: Your subscription's list does not equal to the
                    amount of subscriptions you have. Please contact the admin
                    for more information
                  </Text>
                )}
              {subscriptions &&
                (subscriptions.length > 0 ||
                  (subscriptions.length === 0 && search !== "")) && (
                  <SearchBar setSearch={setSearch} search={search} />
                )}
              {subscriptions && subscriptions.length > 0 && (
                <Table variant="striped" colorScheme="white" mt={3}>
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th flexGrow={1}>Emails</Th>
                      <Th flexGrow={1}>Lines</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {subscriptions.map((email: any, index: any) => {
                      return (
                        <Tr key={index}>
                          <Td>{index + 1}</Td>
                          <Td>{email}</Td>
                          <Td>
                            <Button
                              onClick={async () => await getLine(email)}
                              isLoading={isClicked}
                            >
                              <Icon as={FaTv} />
                            </Button>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              )}
              {subscriptions && subscriptions.length === 0 && search !== "" && (
                <Text textAlign={"center"} fontSize="md" fontWeight={"bold"}>
                  No user found
                </Text>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant={"redBtn"} onClick={onClose}>
              Return
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {viewLine && <LineCard line={viewLine} closeWindow={handleClose} />}
    </>
  );
}

function SearchBar(props: any) {
  return (
    <Formik
      initialValues={{ search: "" }}
      onSubmit={async (values: any, actions: any) => {
        props.setSearch(values.search);
        actions.setSubmitting(false);
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Stack
            spacing={1}
            direction={{ base: "column", md: "row" }}
            alignItems={"center"}
            width={"100%"}
          >
            <Stack direction={"row"} width={"100%"}>
              <Field name="search">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.search && form.touched.search}
                  >
                    <Input
                      {...field}
                      id="search"
                      placeholder="Search by email"
                      w={"full"}
                      variant={"redInput"}
                    />
                  </FormControl>
                )}
              </Field>
              {values.search !== "" && props.search !== "" && (
                <Button
                  isLoading={isSubmitting}
                  variant="redBtn"
                  onClick={() => {
                    props.setSearch("");
                    values.search = "";
                  }}
                >
                  <Icon as={FaEraser} />
                </Button>
              )}
              <Button isLoading={isSubmitting} type="submit">
                <Icon as={FaSearch} />
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

function LineCard({ line, closeWindow }: any) {
  const { hasCopied, onCopy } = useClipboard(
    `Username: ` + line.username + `\nPassword: ` + line.password
  );

  const closeModal = () => {
    closeWindow();
  };
  return (
    <Modal
      isOpen={true}
      onClose={closeModal}
      isCentered
      size={"lg"}
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Line Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {line.username && line.password && (
            <Table variant="striped" colorScheme="white" mt={3}>
              <Thead>
                <Tr>
                  <Th flexGrow={1}>Username</Th>
                  <Th flexGrow={1}>Password</Th>
                  <Th flexGrow={1}>Copy</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{line.username}</Td>
                  <Td>{line.password}</Td>
                  <Td>
                    {" "}
                    <Button onClick={onCopy} ml={2}>
                      {hasCopied ? (
                        <Icon as={FaCheckCircle} />
                      ) : (
                        <Icon as={FaClipboard} />
                      )}
                    </Button>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant={"redBtn"} onClick={closeModal}>
            Return
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
