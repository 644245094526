import React from "react";

import { Stack, Flex, Button, Grid, Avatar } from "@chakra-ui/react";
import {
  FaCoins,
  FaExclamationTriangle,
  FaFutbol,
  FaIdBadge,
  FaReceipt,
  FaTelegram,
  FaUserClock,
  FaUsers,
} from "react-icons/fa";
import { useAuth } from "../components/auth/useAuth";
import firebase from "../utils/firebase";
import "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

import {
  SquareCards,
  LoadingMessage,
  ErrorMessage,
} from "../components/card/Cards";

import ReferralCodeCard from "../components/dashboard/ReferralCode";
import SubscriptionsCard from "../components/dashboard/Subscriptions";
import BalanceCard from "../components/dashboard/Balance";
import PayoutsCard from "../components/dashboard/Payouts";
import TestAccountCard from "../components/dashboard/TestAccount";
import ReportIssueCard from "../components/dashboard/Report";
import SportsCard from "../components/dashboard/Sports";
import TelegramCard from "../components/dashboard/Telegram";
export default function Dashboard(props: any) {
  const { signOut, user } = useAuth();
  const userRef = firebase.firestore().collection("resellers").doc(user.uid);
  const [value, loading, error] = useDocumentData(userRef);

  return (
    <Flex
      w={"full"}
      minH={"100vh"}
      flexDir={"column"}
      bg={"gray.800"}
      overflow={"auto"}
    >
      <Flex
        color={"white"}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={"gray.900"}
        align={"center"}
        shadow={"md"}
      >
        <Flex flex={{ base: 1 }} justify="start">
          <Avatar src={"./images/zebra.png"} mr={2} p={1} bg={"white"} />
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <Button fontSize={"sm"} variant={"redBtn"} onClick={() => signOut()}>
            Sign Out
          </Button>
        </Stack>
      </Flex>
      <Flex
        flexDir={"column"}
        px={{ base: 2, sm: 5 }}
        align={"center"}
        alignItems={"center"}
        pt={10}
        justifyContent={"center"}
        h={"full"}
        mb={"5vh"}
      >
        {error && <ErrorMessage />}
        {loading && <LoadingMessage />}
        {value && (
          <Grid
            gridAutoRows={"1fr"}
            templateColumns={{
              base: "repeat(2, 0.5fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
            gap={{ base: "1rem", sm: "2rem" }}
            w={"full"}
            alignItems={"center"}
            h={"full"}
            justifyContent={"center"}
          >
            {value && (
              <>
                <SquareCards
                  icon={FaIdBadge}
                  text={"Referral Code"}
                  subColor={"red.900"}
                  sub={"" + value.code}
                  Section={ReferralCodeCard}
                  msg={value.code}
                />
                <SquareCards
                  icon={FaUsers}
                  text={"Subscriptions"}
                  subColor={"red.900"}
                  sub={value.subscriptions + " Users"}
                  Section={SubscriptionsCard}
                  msg={value}
                />
                <SquareCards
                  icon={FaCoins}
                  text={"Balance"}
                  subColor={"red.900"}
                  sub={"$" + value.balance + " USD"}
                  msg={value.balance}
                  Section={BalanceCard}
                />
                <SquareCards
                  icon={FaUserClock}
                  text={"Trial"}
                  subColor={"red.900"}
                  sub={"1 Day Subscription"}
                  subSize={"1.5rem"}
                  Section={TestAccountCard}
                  msg={value}
                />
                <SquareCards
                  icon={FaTelegram}
                  text={"Telegram"}
                  subColor={"red.900"}
                  Section={TelegramCard}
                  msg={value}
                />
                <SquareCards
                  icon={FaFutbol}
                  text={"Sports"}
                  subColor={"red.900"}
                  Section={SportsCard}
                  msg={value}
                />
                <SquareCards
                  icon={FaReceipt}
                  text={"Payouts"}
                  Section={PayoutsCard}
                />
              </>
            )}

            <SquareCards
              icon={FaExclamationTriangle}
              text={"Report Issue"}
              Section={ReportIssueCard}
            />
          </Grid>
        )}
      </Flex>
    </Flex>
  );
}
